<template>
  <div>
    <el-button style="margin-left: 3%" v-if="!isSuperAdmin" type="primary" plain @click="dialogFormVisible = true">
      创建复审批次
    </el-button>
    <el-dialog title="新增批次" :visible.sync="dialogFormVisible" width="40%">

      <el-form :model="reviewBatchName" :rules="reviewBatchRules" ref="reviewBatchName">
        <el-form-item label="批次名称" label-width="100px" prop="reviewBatchName">
          <el-input :span="11" v-model="reviewBatchName.name" style="width: 100%" autocomplete="off"></el-input>

        </el-form-item>
      </el-form>
      <div slot="footer" class="dxzialog-footer">
        <el-button type="primary" @click="submitBatch('reviewBatchName')">确 定</el-button>
      </div>
    </el-dialog>
    <div class="root">
      <el-dialog title="导入批次信息" :visible.sync="dialogUpload" width="30%">
        <el-upload
            ref="upload"
            action="http://tzzy.hnjsrcw.com/springboot-hntzzy/certReview/addCertViewInfo.do"
            :file-list="fileList"
            :headers="headers"
            :auto-upload="false"
            :on-error="uploadError"
            :on-success="uploadSuccess"
            :data="data"
            :before-upload="beforeUploadFile"
            :on-change="onchangeFile"

        >
          <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
          <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
          <div slot="tip" class="el-upload__tip" style="font-size: 13px">只能上传xls文件
            <el-link icon="el-icon-download" type="primary" href="template/review.xls" download="复审模板.xls">点击下载模板</el-link>
          </div>
          <div slot="tip" class="el-upload__tip">请认真核对好上传人员名单，重复上传会覆盖上次导入的人员数据，生成证号之后将不可上传</div>
          <div slot="tip" class="el-upload__tip" v-model="errorMsg">{{ errorMsg }}</div>

        </el-upload>
      </el-dialog>
      <el-select v-if="isSuperAdmin" v-model="city" clearable
                 placeholder="请选择" style="width: 100%" @change="selectBatchByCity">
        <el-option

            v-for="item in citys"
            :key="item.name"
            :label="item.name"
            :value="item.value">
        </el-option>
      </el-select>
      <el-table
          stripe
          @select="selectRow"
          class="batchTable"
          size="medium"
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
      >
        <el-table-column
            type="selection"
        >
        </el-table-column>
        <el-table-column
            prop="id"
            label="ID"
            min-width="4"
        >
        </el-table-column>
        <el-table-column
            prop="batchName"
            label="批次名称"
            min-width="15%"
        >
        </el-table-column>
        <el-table-column
            prop="cerateDate"
            label="批次创建日期"
            min-width="6%"
        >
        </el-table-column>
        <el-table-column
            prop="founder"
            label="创建人"
            min-width="3%"

        >
        </el-table-column>

        <el-table-column
            prop="state"
            label="状态"
            min-width="4%"
        >
        </el-table-column>
        <el-table-column
            prop="passTime"
            label="过审日期"
            min-width="6%"
        >
        </el-table-column>
        <el-table-column
            prop="syncDate"
            label="同步日期"
            min-width="6%"
        >
        </el-table-column>
        <el-table-column
            prop="total"
            label="合计"
            min-width="2%"
        >
        </el-table-column>
        <el-table-column
            prop="statistics"
            label="信息"
            min-width="2%"
        >
          <template slot-scope="scope">
            <el-link type="primary" @click="statistics(scope.row.id)">统计</el-link>
          </template>
        </el-table-column>
        <el-table-column
            prop="operate"
            label="操作"
            min-width="2%"
        >
          <template slot-scope="scope">
            <el-link type="primary" @click="importOrView(scope.row)">{{ scope.row.operate }}</el-link>
          </template>
        </el-table-column>
        <el-table-column
            v-if="!isSuperAdmin"
            prop="trash"
            label="废弃"
            min-width="2%"

        >
          <template slot-scope="scope">
            <el-link type="primary" @click="discard(scope.row)">{{ scope.row.trash || "废弃" }}</el-link>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          class="pagina"
          background
          layout="prev, pager, next"
          @current-change="getList"
          :current-page.sync="reviewCurrPage"
          :total="total">
      </el-pagination>
      <hr style="margin-top: 80px"/>
      <el-button-group class="shenhe" v-if="isSuperAdmin">
        <el-button  type="primary" @click="reviewBatch">审核</el-button>
        <el-button  type="warning" @click="fail">不通过</el-button>
      </el-button-group>

      <el-button v-if="!isSuperAdmin" class="shenhe" type="primary" @click="submitReview">申请审核</el-button>
    </div>
  </div>
</template>

<script>
import request from "@/util/request";

export default {
  name: "Index",
  data() {
    let validateReviewBatchName = (rule, value, callback) => {
      if (this.reviewBatchName.name === "") {
        callback(new Error("批次名称不能为空"));
      } else {
        let message = this.$message;
        request({
          url: "/reviewBatch/checkReviewBatchName.do",
          method: "GET",
          params: {
            batchName: this.reviewBatchName.name,
            username: this.$store.getters.currUsername()
          }
        }, res => {
          if (res.code === 200) {
            callback();
          } else {
            callback(new Error(res.msg));
          }

        }, err => {
          console.log(err.msg)
        }, this.$store.getters.token())
      }
    }
    return {
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      city: "*",
      review: [],
      reviewBatchName: {
        name: ""
      },
      dialogFormVisible: false,
      disabled: true,
      reviewBatchRules: {
        reviewBatchName: [{validator: validateReviewBatchName, triangle: 'blur'}]
      },
      dialogUpload: false,
      uploadBatchId: 0,
      fileList: [],
      errorMsg: ""
    }
  }, methods: {
    getList(val) {
      this.$store.commit("setReviewCurrPage", val);

      let url = ''
      if (this.city !== "*" && this.isSuperAdmin) {
        url = '/reviewBatch/getReviewBatchByCity.do'
      } else if (!this.isSuperAdmin) {
        url = "/reviewBatch/getReviewBatchOfCurrUser.do"
      } else
        url = '/reviewBatch/getReviewBatchPage.do';
      request({
        url: url,
        params: {
          pageNum: this.reviewCurrPage,
          pageSize: this.pageSize,
          city: this.city,
          username: this.$store.getters.currUsername()
        },
        method: "GET",
      }, (data) => {
        if (data.code === 200) {
          this.tableData = data.data.list
        } else
          this.$message.error(data.msg);
      }, (err) => {
        console.log(err)
      }, this.$store.getters.token())
    },
    statistics(id) {
      request({
        url: "/reviewBatch/getBatchStatisticsById.do",
        method: "GET",
        params: {
          batchId: id
        }
      }, (result) => {
        result = result.data;
        Object.keys(result).sort();
        let key = [];
        let works = []
        for (let i in result) {
          key.push(i);
        }
        key.sort(function (a, b) {
          return result[a] - result[b];
        });
        for (let k of key) {
          works.push({
            workType: k,
            num: result[k]
          })
          let html = ""
          for (let x of key) {
            html += "<tr><td>" + x + "</td> <td>" + result[x] + "</td></tr>"
          }
          this.$alert('<table id="table_customize">' +
              '    <thead>' +
              '        <tr>' +
              '            <th>名称</th>' +
              '            <th>内容</th>' +
              '        </tr>' +
              '    </thead> <tdody>' + html + '</tbody></table>', {
            dangerouslyUseHTMLString: true
          }).then(() => {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1000
            })
          }).catch(() => {
            this.$message({
              message: "关闭成功",
              type: "success",
              duration: 1000
            })
          });

        }
      }, (err) => {
        console.log(err)
      }, this.$store.getters.token())


    },
    selectRow(val) {
      this.review = val;
    },
    reviewBatch() {
      if (this.review.length > 1){
        this.$alert("暂未支持批量审核，请取消多个选择保留一个")
        this.review = []
      }
        request({
          url: "/reviewBatch/reviewBatch.do",
          method: "GET",
          params: {
            batchId: this.review[0].id
          }
        }, res => {
          this.$alert(res.msg)
        }, err => {
        }, this.$store.getters.token())


      setTimeout(() => {
        this.getList(this.reviewCurrPage);
      }, 1000)

    },
    fail(){
      if (this.review.length === 0){
        this.$alert("请选择不通过的批次");
      }else if (this.review.length > 1){
        this.$alert("暂未支持批量操作，请取消多个选择保留一个")
      }else{
        request({
          url: "/reviewBatch/failBatch.do",
          method: "GEt",
          params:{
            batchId: this.review[0].id
          }
        }, res=>{
          this.$alert(res.msg);
        }, err=>{}, this.$store.getters.token());
      }
      setTimeout(() => {
        this.getList(this.$store.getters.reviewCurrPage);
      }, 1000);
    },

    submitReview(){

      for (let batch of this.review) {
        request({
          url: "/reviewBatch/submitReview.do",
          method: "GET",
          params: {
            batchId: batch.id
          }
        }, res => {
          if (res.code === 200){
            this.$alert("申请审核成功")
          }
        }, err => {
        }, this.$store.getters.token())

        setTimeout(() => {
          this.getList(this.$store.getters.reviewCurrPage);
        }, 1000)
      }
    },
    selectBatchByCity(val) {
      request({
        url: "/reviewBatch/getReviewBatchByCity.do",
        method: "GET",
        params: {
          city: val
        }
      }, res => {
        if (res.code === 200) {
          this.tableData = res.data.list
          this.total = res.data.total
          this.$message({
            message: "查找成功",
            type: "success",
            duration: 1000
          })
        } else {
          this.$message.error("查找失败, 请重新尝试")
        }
      }, err => {
        this.$message.warning("请重新尝试")
      }, this.$store.getters.token())
    },
    submitBatch(reviewBatchName) {
      let message = this.$message;
      this.$refs[reviewBatchName].validate((valid) => {
        if (valid) {
          request({
            url: "/reviewBatch/createBatch.do",
            method: "GET",
            params: {
              batchName: encodeURI(this.reviewBatchName.name),
              founder: this.$store.getters.currUsername()
            }
          }, res => {
            if (res.code === 200) {
              this.$message({
                message: "创建批次成功",
                type: "success",
                duration: 1000
              })
              this.getList(this.reviewCurrPage);
              this.reviewBatchName.name = "";
            } else {
              message.warning("创建批次失败")
            }
          }, err => {
            message.warning("创建批次失败")
          }, this.$store.getters.token())
          this.dialogFormVisible = false;
        } else
          return false;
      })

    },
    importOrView(row) {
      if (row.operate === '====')
        return;
      if (row.operate === "查看") {
        this.$emit("addTabs", "证书列表", "/certReviewList");
        this.$store.dispatch("setCertReviewTable", [row.id])
      } else {
        this.uploadBatchId = row.id;
        this.dialogUpload = true;
      }

    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    beforeUploadFile(file) {
      console.log('before upload');
      console.log(file);
      let extension = file.name.substring(file.name.lastIndexOf('.')+1);
      let size = file.size / 1024 / 1024;
      if(extension !== 'xlsx'&&extension !== 'xls') {
        this.$message.warning('只能上传后缀是.xlsx  .xls的文件');
      }
      if(size > 10) {
        this.$message.warning('文件大小不得超过10M');
      }
    },
    uploadError(err, file, fileList) {
      this.errorMsg = err.msg;
    },
    onchangeFile(file, fileList){
      console.log("文件改变")
      console.log(file)
    },
    uploadSuccess(response, file, fileList) {
      this.$message.success(response.msg);
      this.getList(this.$store.getters.certViewCurrPage);
    },
    discard(row) {
      if (row.trash === '====')
        return;
      request({
        url: "/certReview/discard.do",
        method: "GET",
        params: {
          batchId: row.id
        }
      }, res => {
        if (res.code === 200) {
          row.operate = "===";
          row.trash = "==="
          this.$message.success(res.msg)
        } else
          this.$message.warning(res.msg)
      }, err => {
      }, this.$store.getters.token());
    }
  }, computed: {
    citys: function () {
      return this.$store.state.citys;
    },
    isSuperAdmin() {

      return this.$store.getters.admin();
    },
    reviewCurrPage: {
      get() {
        return this.$store.getters.reviewCurrPage;
      },
      set(currPage) {
        this.$store.commit("setReviewCurrPage", currPage)
      },

    },
    headers() {
      let store = this.$store;

      return {
        authentication: store.getters.token()
      }
    },
    data() {
      return {
        batchId: this.uploadBatchId
      }
    },
  },
  beforeMount() {
    let url = "/reviewBatch/getReviewBatchOfCurrUser.do";
    if (this.$store.getters.admin()) {
      url = "/reviewBatch/getReviewBatchPage.do";
    }
    request({
      url: url,
      params: {
        pageNum: this.$store.getters.reviewCurrPage,
        pageSize: this.pageSize,
        username: this.$store.getters.currUsername()
      },
      method: "GET",
    }, (data) => {
      this.tableData = data.data.list
      this.$store.state.loading = false;
      this.total = data.data.total;
    }, (err) => {
      console.log(err)
    }, this.$store.getters.token())
  },
}
</script>

<style>
.root {
  margin-top: 30px;
  text-align: center;
}

.batchTable {
  width: 100%;
  font-size: 12px;
  margin-top: 40px
}

.pagina {
  margin-top: 30px;
  float: right
}

.shenhe {
  margin-top: 10px
}

#table_customize {
  border-collapse: collapse;
  margin: 0 auto;
  text-align: center;
}

#table_customize td, #table_customize th {
  border: 1px solid #cad9ea;
  color: #666;
  height: 50px;
}

#table_customize thead th {
  background-color: #CCE8EB;
  width: 150px;
}

#table_customize tr:nth-child(odd) {
  background: #fff;
}

#table_customize tr:nth-child(even) {
  background: #F5FAFA;
}

#select-city {
  float: right;
}
</style>